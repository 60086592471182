.banner-block {
  position: relative;

  picture {
      display: flex;
      width: 100%;
      height: 100%;
      min-height: inherit;

      img {
        object-fit: contain;
      }
  }
}

.banner-slider,
.about-slider {
  max-height: 400px;
  margin-bottom: 40px;

  .swiper-wrapper,
  .swiper-slide {
    max-height: inherit;
  }

  .swiper-pagination {
    @include m-b-down(534px) {
      bottom: 2px;
    }

    &-bullet {
      width: 10px;
      height: 10px;
      border-radius: 0;
      &-active {
        background-color: var(--dark);
      }

      @include m-b-down(534px) {
        width: 8px;
        height: 8px;
      }
    }
  }

  &-button-next,
  &-button-prev {
    width: 40px;
    height: 40px;
    background-color: var(--dark);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include m-b-tablet-down {
      display: none;
    }
  }

  &-button-prev {
    background-image: url("../assets/img/icons/slider-button-prev.svg");
  }

  &-button-next {
    background-image: url("../assets/img/icons/slider-button-next.svg");
  }

  img {
    display: block;
    max-height: inherit;
  }
}
