.lw {
  &-text-white {
    color: var(--white);
  }

  &-mt-auto {
    margin-top: auto;
  }
}

.btn {
  cursor: pointer;
}

.accent {
  color: var(--accent);
}

.text-underlined {
  text-decoration: underline;
}
