/* Базовые подключения */
@import "vendor";
@import "vars";
@import "mixins";
@import "fonts";
@import "global";
@import "extends";
@import "common";
//@import "animations";

/*Битрикс*/

@import "./components/bitrix/title-search-result";
@import "./components/bitrix/smart-filter";
@import "./components/bitrix/order";
@import "./components/bitrix/popup";
@import "./components/bitrix/common";

/* Компоненты */
@import "./components/header";

@import "./components/burger";
@import "./components/closer";
@import "./components/social";
@import "./components/city";
@import "./components/header-phones";
@import "./components/search-form";
@import "./components/nav";
@import "./components/personal";
@import "./components/swiper-custom";
@import "./components/slider-section";
@import "./components/main-slider";
@import "./components/card";
@import "./components/favourite-btn";
@import "./components/banner-slider";
@import "./components/client-slider";
@import "./components/counter";
@import "./components/custom-select";
@import "./components/empty-container";

@import "./components/footer";
@import "./components/info-block";
@import "./components/developer";
@import "./components/sidebar";
@import "./components/pagination";
@import "./components/breadcrumbs";

@import "./components/catalog-filter";
@import "./components/range-slider";
@import "./components/rounded-button";
@import "./components/main-checkbox";
@import "./components/thumb-slider";
@import "./components/main-cart-item";
//@import "./components/modal";

/* Секции */

@import "./sections/common/callback-form-section";
@import "./sections/common/clients";

@import "./sections/index-page/hero";
@import "./sections/index-page/catalog-preview";
@import "./sections/index-page/promo";

@import "./sections/catalog/catalog";
@import "./sections/catalog/catalog-section";
@import "./sections/catalog/catalog-element";

@import "./sections/cart/main-cart";
@import "./sections/cart/empty-cart";

@import "./sections/order/order";

@import "./sections/brending/brending";
@import "./sections/common/info-section";

@import "./sections/info-sections/delivery";
@import "./sections/contacts/contacts";

@import "./sections/about/about";
