.nav {
  &__wrapper {
    display: flex;
  }

  .social {
    display: none;
  }

  &__city {
    display: none;
  }

  &__logo {
    display: none;
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__list-item {
    height: 40px;
    padding: 0 20px;

    a {
      position: relative;
      @extend .flex-centered-content;

      height: inherit;

      font-size: 16px;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      padding-top: 10px;

      color: var(--white);

      overflow: hidden;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 2px;
        background-color: var(--accent);

        opacity: 0;
        transition: opacity var(--main-transition);
      }

      &.active::after {
        opacity: 1;
      }

      &:hover,
      &:focus {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  @include m-b-laptop-down {
    display: none;
  }

  .closer {
    display: none;
    position: absolute;
    top: 10px;
    right: 15px;
  }

  @include m-b-laptop-down {
    .closer {
      display: flex;
    }

    &.mobile-opened {
      position: fixed;
      top: 0;
      left: 0;

      width: 100%;
      height: calc(100vh + 70px);

      background-color: rgba(255, 255, 255, 0.2);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);

      @include m-b-down(450px) {
        box-shadow: none;
        backdrop-filter: none;
      }

      z-index: 99;

      .social {
        display: flex;
        margin-bottom: 20px;
        a {
          svg {
            fill: var(--white);
          }

          border-color: var(--white);
        }
      }

      .nav__logo {
        display: block;
        img {
          width: 90px;
          margin-bottom: 20px;
        }
        opacity: 0.4;
      }

      span {
        display: block;
        font-size: 14px;
        color: var(--white);
        text-align: center;
        margin-bottom: 50px;
      }

      .nav__container {
        position: fixed;
        top: 0;
        right: 0;

        height: 100%;
        width: 100%;
        max-width: 450px;

        background-color: var(--dark);
        z-index: 999;

        &::after {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: "";
          width: 80%;
          height: 90%;
          background-image: url(../assets/img/icons/hero-logo.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          opacity: 0.05;
          z-index: -1;
        }
      }

      .nav__wrapper {
        overflow: hidden;
        overflow-y: auto;
        width: 100%;
        height: 100%;
        padding: 30px 0;
        padding-bottom: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      @media(max-height: 419px) {
        .social {
          display: none;
        }

        .nav__city {
          display: none;
        }

        .nav__logo {
          display: none;
        }

        .nav__container:after {
          display: block;
        }
      }

      .nav__list {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: auto;
        flex-grow: 1;

        margin: auto;
        margin-top: 50px;
        padding: 25px;

        &-item a {
          font-size: 18px;
        }
      }
    }
  }
}
