.brending {
  &__content {
    margin-bottom: 60px;

    p {
      font-size: 22px;
      margin-bottom: 10px;

      @include m-b-mobile-lg-down {
        font-size: 20px;
      }
    }

    ul {
      margin-bottom: 30px;

      li {
        font-size: 20px;
        @include m-b-mobile-lg-down {
          font-size: 18px;
        }
      }
    }

    a {
      @extend .hover-opacity;
    }
  }

  .masonry {
    &-sizer,
    &-item {
      width: 19%;

      @include m-b-tablet-lg-down {
        width: 24%;
      }
      @include m-b-tablet-down {
        width: 32%;
      }
      @include m-b-mobile-lg-down {
        width: 49%;
      }
    }

    &-item {
      display: flex;
      margin-bottom: 1%;

      &.hidden {
        display: none;
      }
    }

    .gutter-sizer {
      width: 1%;
    }
  }
}
