.order {
  padding-bottom: 60px;

  &__container {
    display: grid;
    grid-template-columns: auto 360px;
    grid-column-gap: 30px;

    @include m-b-tablet-lg-down {
      grid-template-columns: 1fr;
    }
  }

  &__title {
    font-weight: 400;
    margin-bottom: 30px;
  }

  &__content {
    padding: 20px;

    @include m-b-tablet-lg-down {
      padding: 0;
      order: 2;
    }
  }

  &__sidebar {
    position: sticky;
    top: 30px;
    height: fit-content;
    padding: 30px;
    padding-top: 31px;
    padding-bottom: 32px;
    margin-bottom: 40px;

    background-color: var(--white);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);

    @include m-b-tablet-lg-down {
      position: static;
      order: 1;
      margin-bottom: 30px;
    }

    &-header {
      @extend .flex-wrap;
      align-items: baseline;
      margin-bottom: 10px;

      span {
        display: inline-block;

        font-size: 24px;
        margin-right: 15px;
      }

      a {
        margin-left: auto;
        color: var(--accent);

        @extend .hover-opacity;
      }
    }

    &-body {
      @extend .flex-column;
    }

    &-footer {
      padding-top: 20px;
      &-row {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 10px;

        &:last-child {
          span {
            font-size: 24px;
            font-weight: 500;
            color: var(--dark);
          }
        }

        span {
          font-size: 18px;
          color: var(--lightgray);

          &:nth-child(2n + 1) {
            padding-right: 20px;
          }
        }
      }
    }

    .product {
      display: grid;
      grid-template-columns: 75px auto;
      grid-column-gap: 25px;
      grid-row-gap: 5px;
      padding: 20px 0;

      border-bottom: 1px solid var(--gray-5);

      &-image {
        width: 75px;
        height: 100px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &-title {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 10px;
      }

      &-props {
        @extend .flex-wrap;
      }

      &-prop {
        @extend .flex-wrap;
        font-size: 14px;
        margin-bottom: 5px;
        margin-right: 5px;

        color: var(--basic-gray);
      }

      &-price {
        grid-column: 1/3;
        grid-row: 2/3;
        padding-left: 105px;

        &-current {
          font-size: 24px;
          margin-right: 20px;
        }

        &-old {
          font-size: 18px;
          text-decoration: line-through;
          color: var(--lightgray);
        }
      }
    }
  }
}

.order-accordeon {
  &-item {
    background: var(--white);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    padding: 40px;
    margin-bottom: 20px;

    &-header {
      display: flex;
      align-items: baseline;

      span {
        display: inline-block;
        font-size: 24px;
        padding-right: 20px;
        margin-right: auto;
      }

      button {
        @extend .button-default;
        @extend .flex-centered-content;

        color: var(--accent);

        @extend .hover-opacity;
      }
    }

    &-body {
      padding-top: 20px;
      //display: none;
    }

    &.active {
      .order-accordeon-item-header button {
        //display: none;
      }

      .order-accordeon-item-body {
        //display: block;
      }
    }
  }
}

.order-form-switch {
  &-box {
    @extend .flex-wrap;
    margin-bottom: 20px;
  }

  flex-grow: 1;
  max-width: fit-content;

  margin-right: 20px;
  margin-bottom: 20px;

  padding: 16px 16px;
  background-color: var(--gray-4);
  border: 1px solid var(--gray-5);
  border-radius: 5px;

  transition: border-color var(--main-transition), background-color var(--main-transition);

  label {
    display: inline-block;
    position: relative;
    font-size: 18px;
    padding-left: 40px;
    cursor: pointer;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      content: '';
      width: 24px;
      height: 24px;
      border: 2px solid var(--gray-6);
      border-radius: 50%;

      transition: border-color var(--main-transition);
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);

      content: '';
      width: 8px;
      height: 8px;

      background-color: var(--accent);
      border-radius: 50%;
      opacity: 0;

      transition: opacity var(--main-transition);
    }
  }

  input[type="radio"] {
    display: none;
  }

  &.bx-selected {
    border: 1px solid var(--accent);
    border-radius: 5px;
    background-color: var(--white);
  }

  input[type="radio"]:checked + label::before {
    border: 2px solid var(--accent);
  }

  input[type="radio"]:checked + label::after {
    opacity: 1;
  }
}

.form-field-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 30px;

  &-section {
    height: 100%;
  }

  .textarea-container {
    height: 100%;
  }

  fieldset {
    @extend .fieldset-default;
    @extend .flex-column;

    label {
      &.required::before {
        position: relative;
        top: 0;
        left: 0;

        content: '*';
        color: var(--accent);
      }

      margin-bottom: 10px;
      color: var(--basic-gray);
    }

    input[type="text"],
    textarea {
      padding: 12px;
      margin-bottom: 10px;
      border: 1px solid var(--gray-5);
      border-radius: 5px;
    }

    textarea {
      resize: none;
      min-height: 200px;
      height: 100%;
    }
  }
}

.order-block {
  &-btn {
    max-width: 260px;
    margin-bottom: 25px;
  }

  &-footer {
    span {
      color: var(--basic-gray);
    }
  }
}

.form-description {
  &-box {
    display: grid;
    grid-template-columns: 150px auto;
    grid-column-gap: 30px;
    margin-bottom: 30px;
  }

  &-image {
    height: fit-content;
    border: 1px solid var(--gray-5);
    border-radius: 5px;
    margin-top: 28px;

    img {
      height: 100%;
      object-fit: contain;
    }
  }

  &-text {
    @extend .flex-column;

    margin-top: 28px;

    span {
      display: inline-block;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  &-title {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.order-success {
  img {
    max-width: 360px;
    max-height: 240px;
    object-fit: contain;
    margin-bottom: 30px;

    @include m-b-tablet-down {
      max-height: 140px;
    }

    @include m-b-mobile-lg-down {
      max-height: 100px;
    }
  }

  span {
    display: inline-block;
    font-size: 28px;
    text-align: center;
    margin-bottom: 30px;
    color: var(--dark);

    @include m-b-tablet-down {
      font-size: 24px;
    }

    @include m-b-mobile-lg-down {
      font-size: 20px;
    }
  }

  p {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;

    @include m-b-tablet-down {
      font-size: 22px;
    }

    @include m-b-mobile-lg-down {
      font-size: 18px;
    }
  }

  a {
    font-size: 22px;
    color: var(--accent);

    @include m-b-tablet-down {
      font-size: 20px;
    }

    @include m-b-mobile-lg-down {
      font-size: 18px;
    }
  }

  &__container {
    @extend .flex-column;
    @extend .flex-centered-content;
  }
}
