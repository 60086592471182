.popup-window-overlay {
  position: fixed !important;
}

.popup-window {
  align-items: center;
}

.popup-window.popup-window.popup-window-with-titlebar {
  position: fixed !important;
  border-radius: 6px;
  width: fit-content;
  height: fit-content;
  max-width: 90vw;
  min-width: 280px;
  max-height: 70vh;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%,-50%);
}

.popup-window-with-titlebar .popup-window-content {
  padding: 10px;
}

.popup-window-content img {
  width: 100%;
  height: 100%;
  max-height: calc(50vh - 210px) !important;
  object-fit: contain;
  margin-bottom: 20px;
}
.popup-window-content > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popup-window-content p {
  font-size: 16px;
  color: var(--basic-gray);
}

.popup-window-buttons {
  display: flex;
  align-items: center;
}

.popup-window-buttons .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 20px;

  width: 100%;
  max-width: 240px;
  height: 44px !important;

  font-weight: 500;
  font-size: 16px !important;
  text-align: center;
  letter-spacing: 0.05em;
  color: var(--white);
  border-radius: 5px;
  background-color: var(--accent) !important;
}

.popup-window-content {
  display: none;
}
