.main-cart-item-highlighted {
  background-color: transparent !important;
  color: var(--accent);
}

.cart-item {
  position: relative;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &--removed {
    position: relative;
    transition: all var(--main-transition);

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      z-index: 11;
      background-color: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(5px);
    }

    .cart-item__overlay {
      display: flex !important;
    }
  }

  &__overlay {
    position: absolute;
    z-index: 12;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    strong {
      font-weight: 500;
    }

    button {
      @extend .button-default;
      width: fit-content;
      height: 40px;
      padding: 10px;
      padding-right: 30px;
      background-image: url("../assets/img/icons/restore.svg");
      background-position: right;
      background-size: 24px;
      background-repeat: no-repeat;

      span {
        color: #000000;
      }

      @extend .hover-opacity;
    }
  }

  &__remove {
    @extend .button-default;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background-image: url("../assets/img/icons/close.svg");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
  }

  img {
    display: block;
    width: 75px;
    height: 100px;
    object-fit: contain;
  }

  &__content {
    @extend .flex-wrap;
    margin-bottom: 15px;
  }

  &__footer {
    @extend .flex-wrap;
  }

  &-section {
    &--image {
      width: 75px;
      margin-right: 15px;
    }

    &--desc {
      width: calc(100% - 190px);

      @include m-b-mobile-lg-down {
        width: calc(100% - 90px);
      }
    }
  }

  .product {
    &-discount {
      @extend .flex-centered-content;
      width: 60px;
      height: 24px;
      margin-top: 10px;
      margin-left: auto;

      background-color: var(--dark);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 10% 50%);

      span {
        font-size: 14px;
        color: var(--white);
      }

      position: absolute;
      right: 20px;
      bottom: 60px;

      @include m-b-mobile-lg-down {
        position: static
      }
    }

    &-title {
      @extend .flex-column;
      margin-bottom: 10px;

      h2,a {
        font-size: 18px;
        font-weight: 400;
        color: var(--dark);
      }
    }

    &-artnumber {
      display: inline-block;
      margin-bottom: 10px;
      font-size: 14px;
      color: var(--basic-gray);

      max-width: 210px;
      margin-right: 20px;

      @extend .text-truncate;

      & > span {
        color: var(--dark);
      }
    }

    &-props {
      @extend .flex-wrap;
      height: fit-content;
    }

    &-prop {
      @extend .flex-wrap;
      margin-right: 10px;
      margin-bottom: 5px;

      font-size: 14px;
      color: var(--basic-gray);
    }

    &-price {
      display: flex;
      align-items: center;
      margin-left: auto;

      &-current {
        font-size: 24px;
      }

      &-old {
        padding-left: 15px;
        font-size: 18px;
        text-decoration: line-through;
        color: var(--lightgray);
      }

      @include m-b-mobile-lg-down {
        flex-direction: column;
      }
    }
  }
}


