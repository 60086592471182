.hero {
  background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #CCE4FF 100%);
  background-size: cover;
  background-position: center;

  @include m-b-down(420px) {
    background: transparent;
    margin-bottom: 0;
  }

  &__container {
    position: relative;
    height: 75vh;
    min-height: 500px;
    max-height: 600px;

    @include m-b-tablet-lg-down {
      height: 400px;
      min-height: initial;
      max-height: initial;
    }

    @include m-b-down(600px) {
      height: 220px;
    }

    @include m-b-down(420px) {
      height: 120px;
    }

    picture {
      position: absolute;
      bottom: -20px;
      right: 10%;
      display: block;
      width: 400px;
      height: 100%;

      @include m-b-desktop-down {
        right: 0;
      }

      img {
        width: 100%;
        height: 100%;
        min-height: 500px;
        max-height: 600px;
        object-fit: contain;

        @include m-b-tablet-lg-down {
          height: inherit;
          min-height: inherit;
          max-height: inherit;
        }

        @include m-b-tablet-down {
          object-position: 100% 0%;
        }

        @include m-b-down(420px) {
          display: none;
        }
      }
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      content: '';
      width: 50%;
      height: 80%;

      background-image: url("../assets/img/icons/hero-logo.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center
    }

    @include m-b-down(420px) {
      &::before {
        display: none;
      }
    }
  }

  &__content {
    position: relative;
    @extend .flex-column;
    height: 100%;

    padding: 60px;
    padding-top: 100px;
    padding-right: 45%;
    padding-left: 9%;

    z-index: 3;

    @include m-b-desktop-down {
      padding-left: 20px;
    }

    @include m-b-laptop-down {
      padding: 20px;
      padding-top: 100px;
      padding-right: 45%;
    }

    @include m-b-tablet-down {
      padding-right: 0;
    }

    @include m-b-down(600px) {
      padding: 0;
      .social {
        display: none;
      }
    }

    h1 {
      font-size: 46px;
      font-weight: 500;
      margin: auto 0;
      color: var(--dark);

      @include m-b-tablet-lg-down {
        font-size: 36px;
      }

      @include m-b-tablet-down {
        font-size: 32px;
      }

      @include m-b-down(600px) {
        font-size: 30px;
      }

      @include m-b-mobile-lg-down {
        font-size: 26px;
      }

      @include m-b-down(420px) {
        text-align: center;
      }
    }

    /*.hero__link {
      position: relative;
      @extend .flex-wrap;
      align-items: center;
      width: fit-content;
      height: 50px;
      padding-right: 65px;
      text-transform: uppercase;
      margin-top: auto;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 500;

      color: var(--dark);

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        content:'';
        border-radius: 50%;
        background-color: var(--dark);
        background-image: url("../assets/img/icons/arrow.svg");
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
      }

      @include m-b-tablet-lg-down {
        height: 40px;
        padding-right: 55px;
        margin-top: 0;

        &::before {
          width: 40px;
          height: 40px;
        }
      }

      @include m-b-down(600px) {
        font-size: 16px;
        height: 30px;
        padding-right: 45px;
        margin-top: 0;

        &::before {
          width: 30px;
          height: 30px;
        }
      }
    }*/
  }
}
