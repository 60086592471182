.personal {
  @extend .flex-wrap;
  align-items: center;
  margin-left: auto;

  @include m-b-mobile-lg-down {
    position: fixed;
    bottom: 0;
    z-index: 98;
    width: 100%;
    left: 0;
    justify-content: flex-end;
    padding: 15px;
    background-color: rgba(0,0,0,.7);
  }

  &__field {
    @extend .flex-column;
    @extend .flex-centered-content;
    margin-left: 15px;
    width: fit-content;
    min-width: 40px;
    height: 40px;
    cursor: pointer;

    &-icon {
      position: relative;
      @extend .flex-centered-content;
      margin-bottom: 5px;
      opacity: 1;

      @include m-b-mobile-lg-down {
        margin: 0;
      }

      transition: opacity var(--main-transition);

      span {
        @extend .flex-centered-content;
        position: absolute;
        top: -5px;
        left: 50%;

        border-radius: 5px;
        width: fit-content;
        height: 16px;

        font-size: 12px;
        font-weight: 700;
        padding: 4px;
        color: var(--white);
        background-color: var(--accent);
      }
    }

    & > span {
      font-size: 16px;
      color: var(--white);

      @include m-b-tablet-lg-down {
        display: none;
      }
    }

    &:hover &-icon,
    &:focus &-icon, {
      opacity: .5;
    }

    @include m-b-mobile-lg-down {
      width: 60px;
      border-radius: 4px;
      background-color: rgba(245,245,245,.2);
    }

    &--profile {
      position: relative;

      .personal-field-profile-inner {
        display: none;
        position: absolute;
        top: calc(100% + 10px);
        background-color: var(--white);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        padding: 20px;
        width: fit-content;
        flex-direction: column;
        opacity: 0;
        overflow: hidden;
        transition: all .6s ease-in;

        a {
          display: flex;
          white-space: nowrap;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }

        @include m-b-mobile-lg-down {
          top: initial;
          bottom: calc(100% + 10px);
        }
      }
    }

    &.active {
      .personal-field-profile-inner {
        display: flex;
        opacity: 1 !important;
        z-index: 99;
      }
    }
  }
}
