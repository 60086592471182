.developer {

  a {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }

  img {
    width: 180px;
    height: 60px;
    object-fit: contain;
  }
}
