.range-slider {
  width: fit-content;

  .smart-filter-slider-price-bar-vd,
  .smart-filter-slider-price-bar-vn {
    background-color: var(--lightgray);
  }

  .smart-filter-slider-price-bar-v {
    background-color: var(--accent);
  }

  &__input-container {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    input[type="number"] {
      display: flex;
      width: fit-content;
      max-width: 100px;
      background-color: var(--white);
      border: 1px solid var(--basic-gray);
      border-radius: 5px;
      padding: 10px 25px;
      text-align: center;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .range-slider__input-between {
      width: 10px;
      height: 2px;
      margin-right: 10px;
      background-color: var(--dark);
    }
  }

  &__track {
    /*&-outer {
      position: relative;
      width: 100%;
      height: 6px;
      padding: 0 3px;
    }*/

    &-inner {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 3px;
    }
  }

  &__handle {
    @extend .btn-reset;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    content: '';
    border-radius: 50%;
    z-index: 89;
    border: 2px solid var(--white);

    background-color: var(--accent);

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--white);
    }

    &--right {
      right: 0;
    }

    &--left {
      left: 0;
    }
  }
}
