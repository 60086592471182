.callback {
    min-height: 260px;

    background-image: url("../assets/img/form-bg@1x.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    padding: 30px 0;

    @include m-b-laptop-down {
      padding: 30px 0;
    }

    @include m-b-tablet-lg-down {
      padding: 20px 0;
    }

    &__title {
      @extend .title-md;
      font-weight: 500;
      margin-bottom: 25px;
    }

    form {
      width: 100%;
      max-width: 590px;

      fieldset {
        @extend .fieldset-default;
        display: flex;

        @include m-b-down(600px) {
          flex-direction: column;
        }
      }

      input {
        @extend .input-default;
      }

      *[type="submit"] {
        @extend .accent-btn;
        max-width: 300px;
        margin-bottom: 20px;
      }

      input:not(input[type="checkbox"]):not(input[type="submit"]) {
        width: 100%;
        height: 60px;
        margin-right: 15px;
        margin-bottom: 15px;
        padding: 15px;
        font-size: 20px;
        color: var(--dark);
        background-color: var(--white);
        border-radius: 5px;
        border: 1px solid transparent;

        &::placeholder {
          font-size: 20px;
          color: var(--basic-gray);
        }

        @include m-b-desktop-down {
          height: 50px;
          padding: 10px;
        }

        @include m-b-laptop-down {
          height: 40px;
          font-size: 18px;
        }

        @include m-b-down(600px) {
          margin-right: 0;
        }
      }

      /*Кастомный чекбокс*/
      input[type="checkbox"] + label {
        position: relative;
        cursor: pointer;

        @extend .hover-opacity;
      }

      input[type="checkbox"] + label::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 20px;
        height: 20px;
        background-color: var(--white);
        border: 2px solid #E6EAF0;
        border-radius: 3px;
      }
      input[type="checkbox"]:checked + label::before {
        background-image: url("../assets/img/icons/checkbox-arrow.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
      /*Кастомный чекбокс*/

      fieldset:last-of-type {
        margin-bottom: 25px;
      }

      label {
        font-size: 16px;
        color: var(--basic-gray);
        padding-left: 25px;

        a {
          color: var(--accent);
        }

        @include m-b-down(600px) {
          font-size: 14px;
        }
      }

      small {
        display: inline-block;
        font-size: 14px;
        color: var(--danger);
      }

      .error-note {
        margin: 10px 0;
        opacity: 0;
        transition: opacity var(--main-transition);

        &.active {
          opacity: 1;
        }
      }
    }
}

.success-info {
  display: flex;
  flex-direction: column;
}

.success-info-title {
  display: inline-block;
  font-size: 36px;
  margin-bottom: 15px;
  color: var(--accent);
}

.success-info-text {
  display: inline-block;
  font-size: 22px;
  margin-bottom: 15px;
  color: var(--dark);
}
