.counter {
  display: flex;
  align-items: center;
  border: 1px solid var(--gray-5);
  border-radius: 5px;
  width: 120px;
  height: 44px;

  &__btn {
    @extend .btn-reset;
    width: 40px;
    height: inherit;
    font-size: 24px;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;

    &--dec {
      border-radius: 5px 0 0 5px;
      background-image: url("../assets/img/icons/minus.svg");
    }

    &--inc {
      border-radius: 0 5px 5px 0;
      background-image: url("../assets/img/icons/plus.svg");
    }

    transition: all var(--main-transition);

    &:active {
      background-color: var(--light);
      opacity: .2;
    }
  }

  &__value{
    @extend .flex-centered-content;
    font-weight: 500;
    font-size: 20px;
    width: 40px;
    height: 28px;

    border: 0;
    border-right: 1px solid var(--gray-5);
    border-left: 1px solid var(--gray-5);
    text-align: center;

    &::placeholder {
      @extend .flex-centered-content;
    }
  }
}
