*,
*::before,
*::after {
  box-sizing: inherit;
  border-radius: 0;

  @extend .main-scroll;
}

html {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  min-height: 100vh;
  -webkit-text-size-adjust: none;
}

svg {
  fill: var(--white);
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family, sans-serif);
  line-height: 1.2;
  font-weight: 400;
  background-color: var(--main-bg-color);

  &.body-locked {
    overflow: hidden;
  }
}

footer {
  margin-top: auto;
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: var(--dark);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}


/*Прячем стрелки и тд в инпутах*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

section {
  padding: 20px 0;
}
