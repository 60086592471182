.blue-logo {
  position: absolute;
  bottom: 40px;
  left: 40px;
  content: '';
  background-image: url("../assets/img/icons/logo-blue.svg");
  width: 80px;
  height: 60px;

  @include m-b-desktop-down {
    bottom: 20px;
    left: 20px;
  }

  @include m-b-tablet-lg-down {
    bottom: 15px;
    left: 15px;
  }

  @include m-b-down(700px) {
    bottom: 10px;
    left: 10px;
  }

  @include m-b-down(600px) {
    top: 10px;
    bottom: initial;
    left: 10px;

    width: 40px;
    height: 30px;
    background-size: contain;
  }
}

.catalog-preview {
  &__grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(12, 7vw);
    grid-gap: 30px;
    margin-bottom: 30px;

    @include m-b-desktop-lg-up {
      grid-template-rows: repeat(12, 134px);
    }

    @include m-b-down(600px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 150px);
    }

    @include m-b-mobile-lg-down {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(10, 150px);
    }

    @include m-b-laptop-down {
      grid-gap: 20px;
      margin-bottom: 20px;
    }

    @include m-b-tablet-down {
      grid-gap: 15px;
      margin-bottom: 15px;
    }

    picture {
      display: flex;
      width: 100%;
      height: 100%;

      img {
        object-fit: contain;
        object-position: bottom right;
      }
    }

    &-title {
      position: absolute;
      top: 1px;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      padding: 40px;

      @include m-b-desktop-down {
          padding: 20px;
      }

      @include m-b-tablet-lg-down {
          padding: 15px;
      }

      @include m-b-down(700px) {
        padding: 10px;
      }

      @include m-b-down(600px) {
        padding: 5px 10px;
      }

      @include m-b-down(600px) {
        clip-path: polygon(0 60%, 100% 80%, 100% 100%, 0% 100%);
        background-color: rgba(245,245,245,.7);
      }

      h3 {
          position: relative;
          font-weight: 400;
          font-size: 36px;
          line-height: 1;
          color: var(--dark);
          z-index: 2;

          @include m-b-desktop-down {
              font-size: 32px;
          }

          @include m-b-laptop-down {
              font-size: 28px;
          }

          @include m-b-tablet-lg-down {
            font-size: 24px;
          }

          @include m-b-tablet-down {
            font-size: 22px;
          }

          @include m-b-down(600px) {
            margin-top: auto !important;
          }
      }
    }

    &-item {
      position: relative;
      background-color: var(--gray-4);
      overflow: hidden;

      background-size: cover;

      @include m-b-down(600px) {
        background-image: url("../assets/img/cp-common@1x.png") !important;
      }

      picture img {
        transition: transform 1s 0.1s linear;
      }


      &:hover,
      &:focus {
        picture img {
          transform: scale(1.03);
        }
      }

      &:nth-child(1) {
        grid-column: 1/5;
        grid-row: 1/4;

        background-image: url("../assets/img/cp-1-bg@1x.png");

        @include m-b-down(600px) {
          grid-column: 1/2;
          grid-row: 1/2;
        }

        @include m-b-mobile-lg-down {
          grid-column: 1/2;
          grid-row: 1/2;
        }
      }

      &:nth-child(2) {
        grid-column: 5/7;
        grid-row: 1/4;

        background-image: url("../assets/img/cp-2-bg@1x.png");

        .catalog-preview__grid-title h3 {
          margin-top: auto;
        }

        @include m-b-down(600px) {
          grid-column: 2/3;
          grid-row: 1/2;
        }

        @include m-b-mobile-lg-down {
          grid-column: 1/2;
          grid-row: 2/3;
        }
      }

      &:nth-child(3) {
        grid-column: 1/3;
        grid-row: 4/7;

        background-image: url("../assets/img/cp-3-bg@1x.png");

        .catalog-preview__grid-title h3 {
          margin-top: auto;
        }

        @include m-b-down(600px) {
          grid-column: 1/2;
          grid-row: 2/3;

          picture img {
            object-fit: cover;
          }
        }

        @include m-b-mobile-lg-down {
          grid-column: 1/2;
          grid-row: 3/4;

          picture img {
            object-fit: contain;
          }
        }
      }

      &:nth-child(4) {
        position: relative;
        grid-column: 3/7;
        grid-row: 4/7;

        & > a::after {
          @extend .blue-logo;
        }

        background-image: url("../assets/img/cp-4-bg@1x.png");

        @include m-b-down(600px) {
          grid-column: 2/3;
          grid-row: 2/3;
        }

        @include m-b-mobile-lg-down {
          grid-column: 1/2;
          grid-row: 4/5;
        }
      }

      &:nth-child(5) {
        grid-column: 1/5;
        grid-row: 7/10;
        background-image: url("../assets/img/cp-5-bg@1x.png");

        .catalog-preview__grid-title h3 {
          margin-top: auto;
        }

        @include m-b-down(600px) {
          grid-column: 1/2;
          grid-row: 3/4;

          .catalog-preview__grid-title h3 {
            margin-top: 0;
          }
        }

        @include m-b-mobile-lg-down {
          grid-column: 1/2;
          grid-row: 5/6;

          .catalog-preview__grid-title h3 {
            margin-top: auto;
          }
        }
      }

      &:nth-child(8) {
        grid-column: 5/7;
        grid-row: 7/9;
        background-image: url("../assets/img/cp-8-bg@1x.png");

        @include m-b-down(600px) {
          grid-column: 2/3;
          grid-row: 4/5;
        }

        @include m-b-mobile-lg-down {
          grid-column: 1/2;
          grid-row: 6/7;
        }
      }

      &:nth-child(9) {
        grid-column: 5/7;
        grid-row: 9/11;
        background-image: url("../assets/img/cp-9-bg@1x.png");

        @include m-b-down(600px) {
          grid-column: 2/3;
          grid-row: 3/4;
        }

        @include m-b-mobile-lg-down {
          grid-column: 1/2;
          grid-row: 7/8;
        }
      }

      &:nth-child(10) {
        grid-column: 5/7;
        grid-row: 11/13;
        background-image: url("../assets/img/cp-10-bg@1x.png");

        @include m-b-down(600px) {
          grid-column: 2/3;
          grid-row: 5/6;
        }

        @include m-b-mobile-lg-down {
          grid-column: 1/2;
          grid-row: 8/9;
        }
      }

      &:nth-child(6) {
        grid-column: 1/3;
        grid-row: 10/13;

        picture img {
          object-fit: cover;
        }

        .catalog-preview__grid-title h3 {
          color: var(--white);
        }

        @include m-b-down(600px) {
          grid-column: 1/2;
          grid-row: 5/6;

          .catalog-preview__grid-title {
            background-color: rgba(0, 0, 0, 0.3);
          }

          picture img {
            object-position: center;
          }
        }

        @include m-b-mobile-lg-down {
          grid-column: 1/2;
          grid-row: 9/10;
        }
      }

      &:nth-child(7) {
        grid-column: 3/5;
        grid-row: 10/13;

        picture img {
          object-fit: cover;
        }

        .catalog-preview__grid-title h3 {
          color: var(--white);
        }

        @include m-b-down(600px) {
          grid-column: 1/2;
          grid-row: 4/5;

          .catalog-preview__grid-title {
              background-color: rgba(0, 0, 0, 0.3);
          }

          picture img {
            object-position: center;
          }
        }

        @include m-b-mobile-lg-down {
          grid-column: 1/2;
          grid-row: 10/11;
        }
      }
    }
  }
}
