.main-cart {
  @extend .section-inner-offset-md;
  &__header {
    @extend .flex-wrap;
    align-items: center;
    margin-bottom: 25px;
    max-width: 1200px;

    h1 {
      width: 100%;
      padding-right: 15px;
      margin-bottom: 30px;

      @include m-b-mobile-lg-down {
        flex-grow: 1;
        width: auto;
        margin-bottom: 0;
      }
    }
  }

  &__search {
    position: relative;
    height: 40px;
    margin-right: 50px;
    flex-grow: 1;
    max-width: 450px;

    input {
      width: 100%;
      font-size: 18px;
      padding: 10px;
      padding-right: 50px;
      background: var(--gray-4);
      border: none;
      border-radius: 5px;

      &::placeholder {
        color: var(--dark);
      }
    }

    button {
      @extend .button-default;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 100%;
      background-image: url("../assets/img/icons/close.svg");
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: center;
    }

    @include m-b-mobile-lg-down {
      order: 3;
      max-width: 100%;
      margin-right: 0;
      margin-top: 30px;
    }
  }

  &__total-count {
    font-size: 14px;
    color: var(--basic-gray);
  }

  &__content {
    display: grid;
    grid-template-columns: auto 360px;
    grid-column-gap: 30px;
    max-width: 1200px;

    @include m-b-tablet-lg-down {
      grid-template-columns: 1fr;
    }
  }

  &__list {
    @extend .flex-column;
    height: fit-content;

    @include m-b-tablet-lg-down {
      margin-bottom: 30px;
    }
  }

  &__items-wrapper {
    width: 100%;
    height: 100%;
  }

  &__checkout {
    position: sticky;
    top: 150px;

    display: flex;
    flex-direction: column;

    height: fit-content;
    background-color: var(--white);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    padding: 20px;
    min-height: 160px;

    &-container {
      display: flex;
      flex-direction: column;
      min-height: inherit;
    }

    &-row {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 18px;
        color: var(--lightgray);
      }

      &--total {
        span {
          font-size: 24px;
          color: #000000;
        }
      }

      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 30px;
      }
    }

    button {
      margin-top: auto;
    }
  }
}
