.delivery {
	&__subtitle {
		font-weight: 400;
		margin-bottom: 5px;
		color: var(--dark);
	}

	&__field {
		margin-bottom: 30px;
	}

	&__field a {
		color: var(--accent);
	}

	&__field p {
		margin-bottom: 5px;
	}

  &__field-title {
		font-weight: 500;
		margin-bottom: 30px;
		color: var(--dark);
	}
}
