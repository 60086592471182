.product-slider {
  position: relative;
  overflow: hidden;
  height: auto;

  width: 400px;
  user-select: none;
  margin-right: 10px;

  @include m-b-laptop-down {
    width: 320px;
  }

  @include m-b-down(900px) {
    width: 260px;
  }

  @include m-b-mobile-lg-down {
    width: calc(100% - 80px);
    max-width: 260px;
  }

  &-container {
    display: flex;
  }

  &::before {
    position: absolute;
    bottom: 20px;
    left: 20px;
    content: '';

    width: 76px;
    height: 56px;
    background-image: url("../assets/img/icons/product-logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;

    @include m-b-tablet-lg-down {
      bottom: 10px;
      left: 10px;
    }

    @include m-b-down(492px) {
      width: 51px;
      height: 37px;
      bottom: 5px;
      left: 5px;
    }

    @include m-b-down(420px) {
      width: 76px;
      height: 56px;
      bottom: 10px;
      left: 10px;
    }
  }
}

.product-slider-thumbs {
  overflow: hidden;
  .swiper-slide {
    width: 100px;
    height: 150px;
    opacity: .5;
    transition: opacity var(--main-transition);

    @include m-b-mobile-lg-down {
      width: 60px;
      height: 90px;
    }
  }

  .swiper-slide-thumb-active {
    opacity: 1;
  }
}

.product-slider,
.product-slider-thumbs {
  max-height: 600px;

  @include m-b-laptop-down {
    max-height: 480px;
  }

  @include m-b-down(900px) {
    max-height: 390px;
  }

  .swiper-slide {
    background-color: var(--card-bg);

    img {
      display: flex;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 2/3;
    }
  }
}

.product-slider-button-next,
.product-slider-button-prev {
  position: absolute;
  top: 50%;
  background-color: transparent;

  &::before {
    width: 36px;
    height: 36px;
    background-size: cover;
  }
}

.product-slider-button-next {
  @extend .main-slider-button-next;
  right: 0;
  left: initial;
  transform: translateY(-50%);

  @include m-b-tablet-lg-down {
    right: -10px;
  }

  @include m-b-tablet-down {
    display: none;
  }
}

.product-slider-button-prev {
  @extend .main-slider-button-prev;
  left: 0;
  transform: translateY(-50%) rotate(180deg);

  @include m-b-tablet-lg-down {
    left: -10px;
  }

  @include m-b-tablet-down {
    display: none;
  }
}
