.closer {
  @extend .button-default;
  @extend .flex-centered-content;

  width: 30px;
  height: 30px;

  transform: rotate(45deg);

  &__line {
    width: 100%;
    height: 3px;
    background-color: var(--white);
    border-radius: 3px;

    &--top {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &--bottom {
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      position: absolute;
    }
  }
}
