.breadcrumbs {
  display: flex;
  align-items: center;
  padding: 20px 0;

  @include m-b-down(600px) {
    display: none;
  }

  span {
    font-weight: 500;
    @extend .text-truncate;
    color: var(--accent);
  }

  a {
    position: relative;
    font-weight: 500;
    padding-right: 20px;
    @extend .text-truncate;

    & > span {
      color: var(--dark);
    }

    &::after {
      position: absolute;
      top: 0;
      right: 4px;
      content: "/";
    }

    @extend .hover-opacity;
  }


}
