.footer {
  background-color: var(--dark);

  @include m-b-mobile-lg-down {
    padding-bottom: 70px;
  }

  &__top {
    @extend .section-inner-offset;
    @extend .flex-wrap;
    align-items: center;

    @include m-b-laptop-down {
      flex-direction: column;
    }
  }

  &__logo {
    @include m-b-laptop-down {
      margin-bottom: 30px;
    }
  }

  .nav {
    @extend .flex-wrap;
    flex-grow: 1;

    &__list {
      margin: 0 auto;

      @include m-b-tablet-down {
        flex-direction: column;
      }
    }

    &__list-item {
      @include m-b-tablet-lg-down {
        padding: 0 10px;
      }
    }

    @include m-b-laptop-down {
      margin-bottom: 30px;
    }
  }

  .social {
    svg {
      fill: var(--white);
    }
    a {
      border: none;
    }

    a:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 25px;
    @extend .section-inner-offset;
    border-top: 1px solid var(--white);

    @include m-b-laptop-down {
      grid-template-columns: repeat(2, auto);
    }

    @include m-b-tablet-down {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .law {
    max-width: 500px;
    font-size: 14px;
    color: var(--lightgray);
    margin: 0 auto;

    @include m-b-laptop-down {
      width: 100%;
      max-width: 100%;
      order: 2;
    }
  }

  .company-info {
    &__field {
      @extend .flex-wrap;

      a,address,span {
        display: flex;
        font-size: 14px;
        font-style: normal;
        color: var(--lightgray);
        margin-bottom: 5px;
      }

      @include m-b-tablet-down {
        align-items: center;
      }
    }

    a {
      @extend .hover-opacity;
    }
  }

  .info-side-block {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-left: auto;
    height: 100%;

    @include m-b-laptop-down {
      order: 1;
    }

    @include m-b-tablet-down {
      margin: 0 auto;
    }

    .payment-logo {
      margin-bottom: 20px;

      img {
        display: block;
      }
    }

    .developer {
      margin-top: auto;
    }
  }

  @include m-b-tablet-down {
    .law,
    .company-info {
      width: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    .law {
      order: 2;
    }
  }
}
