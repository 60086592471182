.social {
  display: flex;
  margin-top: auto;

  a {
    @extend .flex-centered-content;
    width: 50px;
    height: 50px;

    border: 2px solid var(--blue);
    background-color: rgba(245,245,245,0.05);

    svg {
      fill: var(--blue);
    }

    &:first-of-type {
      width: 100px;
    }

    @include m-b-tablet-lg-down {
      width: 40px;
      height: 40px;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}
