.search-title-button {
	background-image: url(images/icon-search.svg);
	background-repeat: no-repeat;
	background-position: center;
	min-width: 45px;
	min-height: 38px;
}

.title-search-result {
	display: none;
	overflow: hidden;
	z-index: 205;
	background-color: var(--white);
	border-radius: 5px;
  margin-top: 2px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
}

.search-title-result-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.search-title-result-item-image-container {
	margin-right: 10px;
}

.search-title-result-item-info > span {
	font-weight: 400;
	font-size: 16px;

	color: var(--basic-gray);
}

.search-title-result-item-info > span > b {
	font-weight: 500;
	color: var(--accent);
}

.search-title-result-item-current-price {
	font-size: 16px;
	font-weight: 500;
	color: var(--dark);
}

.search-title-result-item-old-price {
	font-size: 16px;
	text-decoration: line-through;
	color: var(--basic-gray);
}

.search-title-result-item:last-child {
  	border-top: 1px solid var(--gray-4);
}

.search-title-result-item:hover,
.search-title-result-item:focus {
    background-color: var(--gray-4);
}

.search-title-result-item-link {
  font-size: 16px;
  color: var(--dark);
}

.search-title .search-title-result-item:last-child {
	border-bottom:  none;
}

.search-title-result-item-link {
	font-size: 16px;
	transition: 170ms linear all;
}

.search-title-result-item-info > span.search-title-show-all {
	color: var(--dark);
}
