/* stylelint-disable color-function-notation */
/* stylelint-disable alpha-value-notation */

:root {
    // base
    --font-family: "Futura", sans-serif;
    --content-width: 1590px;
    --content-sm-width: 1200px;
    --content-fluid-width: 1890px;
    --container-offset: 15px;
    --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
    --container-sm-width: calc(var(--content-sm-width) + (var(--container-offset) * 2));
    --container-fluid-width: calc(var(--content-fluid-width) + (var(--container-offset) * 2));

    // colors
    --white: #fff;
    --light: #ebebeb;
    --basic-gray: #8c8c8c;
    --accent: #d1691d;
    --dark: #353434;
    --lightgray: #a4a4a4;
    --gray-6: #d3d3d3;
    --gray-4: #f5f5f5;
    --gray-5: #cfd4df;
    --blue: #195ea6;
    --skyblue: rgba(101, 160, 225, .9);
    --discount: var(--lightgray);
    --basic-text: rgba(20, 16, 36, 0.7);
    --header-bg: #242424;
    --card-bg: rgba(101, 160, 225, .2);
    --badge-top: #824B48;
    --badge-sale: var(--dark);
    --badge-new: #AF9061;
    --success: #469c59;
    --danger: #9c150c;

    --main-bg-color: var(--white);
    --main-transition: 0.3s linear;

    // scrollbar
    --main-scrollbar-width: 8px;
    --main-scrollbar-color: var(--dark);
    --main-scrollbar-thumb-color: var(--accent);
  }
