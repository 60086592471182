
$max-width: 240px;
$max-width-mobile: 204px;
$min-width: 75px;

.custom-select-container {
  position: relative;
  box-sizing: border-box;;
  width: 100%;
  max-width: $max-width;

  @include m-b-down(492px) {
    max-width: $max-width-mobile;
  }
}
.custom-select-container * {
  box-sizing: border-box;
}
.custom-select-container.is-disabled {
  opacity: .333;
}

.custom-select-opener {
  position: relative;
  display: block;
  width: 100%;
  max-width: $max-width;
  min-width: $min-width;
  padding: 5px;
  padding-right: 40px;

  font-size: 16px;

  border: 1px solid var(--gray-5);
  border-radius: 5px;

  background-color: var(--white);
  cursor: pointer;

  @extend .text-truncate;

  &::after {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    content: '';
    width: 24px;
    height: 24px;
    background-image: url("../assets/img/icons/select-arrow.svg");
  }

  @include m-b-down(492px) {
    font-size: 16px;
    padding: 5px;
    padding-right: 40px;

    max-width: $max-width-mobile;
  }
}

.custom-select-container select {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.custom-select-panel {
  position: absolute;
  top: calc(100% + 5px);
  width: 100%;
  max-height: 0;
  border-radius: 5px;
  background-color: var(--white);

  z-index: 1;
  overflow: hidden;
}

.custom-select-container.is-open .custom-select-panel {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid var(--basic-gray);
}

.custom-select-option {
  display: block;
  width: 100%;
  padding: 5px;

  font-size: 16px;

  background-color: var(--white);
  cursor: pointer;

  @extend .text-truncate;
}

.custom-select-option.has-focus {
  background-color: var(--gray-4);
}
