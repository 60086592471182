.sidebar {
  background-color: var(--white);

  &__header {
    display: none;
    align-items: center;
    justify-content: space-between;

    padding: 30px 20px;
    padding-left: 0;
    span {
      font-size: 28px;
      padding-right: 20px;
    }
    .closer__line {
      background-color: var(--dark);
    }
  }

  @include m-b-up(1001px) {
    display: block !important;
    opacity: 1 !important;

    &__container {
      opacity: 1 !important;
      transform: initial !important;
    }
  }

  @include m-b-down(1000px) {
    &__header {
      display: flex;
    }

    display: none;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);

    &__container {
      width: 100%;
      max-width: 320px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      padding-left: 10px;
      overflow: hidden;
      overflow-y: auto;
      background-color: var(--white);
      box-shadow: 2px 0 4px 0px rgba(0,0,0,.07);
    }
  }
}
