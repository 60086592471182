.search-form {
  position: relative;
  flex-grow: 1;
  max-width: 480px;
  height: 44px;

  border-radius: 5px;
  margin-left: auto;

  @include m-b-tablet-down {
    width: 36px;
    height: 36px;
  }

  input {
    @extend .input-default;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 10px 60px 12px 15px;

    font-size: 18px;

    color: var(--dark);

    &::placeholder {
      font-size: 18px;

      color: var(--lightgray);
    }
  }

  button {
    @extend .button-default;
    @extend .flex-centered-content;
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: var(--accent);

    @extend .hover-opacity;

    @include m-b-tablet-down {
      width: 32px;
      height: 32px;
    }
  }
}
