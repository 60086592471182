.catalog-element {
  .product-item {
    position: relative;
    display: grid;
    grid-template-columns: 510px auto;
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    margin-bottom: 60px;

    @include m-b-laptop-down {
      grid-template-columns: 430px auto;
      grid-column-gap: 40px;
      grid-row-gap: 40px;
      margin-bottom: 30px;
    }

    @include m-b-tablet-lg-down {
      grid-column-gap: 30px;
      grid-row-gap: 30px;
    }

    @include m-b-down(900px) {
      grid-template-columns: 370px auto;
    }

    @include m-b-down(700px) {
      display: flex;
      flex-direction: column;
    }

    &__preview {
      height: fit-content;
      overflow: hidden;
      position: sticky;
      top: 20px;

      @include m-b-down(700px) {
        position: relative;
      }

      .badge {
        @extend .badge;
        height: 30px;
        left: 10px;

        @include m-b-mobile-lg-down {
          left: -5px;
        }
      }
    }
  }

  .product-item__detail {
    &-header {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid var(--lightgray);

      h1 {
        font-size: 48px;
        font-weight: 400;
        color: var(--basic-text);

        @include m-b-laptop-down {
          font-size: 42px;
        }

        @include m-b-tablet-lg-down {
          font-size: 38px;
        }

        @include m-b-tablet-down {
          font-size: 36px;
        }

        @include m-b-mobile-lg-down {
          font-size: 32px;
        }
      }
    }

    &-availability {
      margin-bottom: 15px;

      span {
        position: relative;
        padding-left: 14px;
        font-weight: 500;
        font-size: 18px;
        color: var(--basic-text);

        &::before{
          position: absolute;
          top: 9px;
          left: 0;
          width: 8px;
          height: 8px;
          content: '';
          border-radius: 50%;
        }

        &.available::before {
          background-color: var(--success);
        }

        &.not-available::before {
          background-color: var(--danger);
        }
      }
    }

    &-price {
      @extend .flex-wrap;
      align-items: center;
      margin-bottom: 20px;

      span {
        display: inline-block;
        font-size: 48px !important;
        font-weight: 500;
        margin-right: 15px;
        color: var(--basic-text);

        &.old-price {
          text-decoration: line-through;
          font-size: 32px !important;
          font-weight: 400;
          color: var(--lightgray);
        }
      }
    }

    &-options {
      &-title {
        display: inline-block;
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 20px;
      }
      margin-bottom: 40px;
    }

    &-option {
      @extend .flex-wrap;
      align-items: center;
      padding-bottom: 5px;
      border-bottom: 1px dashed var(--lightgray);

      &-name {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
        width: 100%;
        max-width: 110px;
        font-size: 18px;
        color: var(--basic-gray);
      }

      &-value {
        font-size: 18px;
        margin-bottom: 10px;
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    &-footer {
      @extend .flex-wrap;
      align-items: flex-end;
      margin-top: auto;
      margin-bottom: 15px;

      @include m-b-down(420px) {
        align-items: flex-start;
        flex-direction: column;
      }

      &-counter {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 30px;

        @include m-b-down(420px) {
          margin-bottom: 20px;
        }

        span {
          display: inline-block;
          font-size: 18px;
          margin-bottom: 10px;
          color: var(--basic-gray);
        }
      }

      &-btn {
        max-width: 240px;
        max-height: 44px;
        margin-bottom: 30px;
      }
    }

    &-note {
      display: block;
      color: var(--dark);

      p {
        font-size: 18px;
        font-weight: 500;

        span {
          text-decoration: underline;
          font-weight: 500;
          color: var(--accent);
        }

        @include m-b-down(420px) {
          font-size: 16px;
        }
      }
    }
  }
}

.product-description {
  padding: 10px 0;
  margin-bottom: 15px;

  &__title {
    display: inline-block;
    margin-bottom: 10px;

    font-size: 28px;
    color: var(--basic-gray);
  }

  &__text {
    font-size: 20px;
  }
}
