.range-slider__price {
	display: flex;
	width: fit-content;
	max-width: 100px;
	background-color: var(--white);
	border: 1px solid var(--basic-gray);
	border-radius: 5px;
	padding: 10px 25px;
	text-align: center;
}


.range-slider__price:not(:last-child) {
	margin-right: 10px;
}

.range-slider__track {
	position: relative;
    height: 4px;
    margin: 25px 0;
}

.range-slider__line {
	position:absolute;
	top:-1px;
	bottom:-1px;
	height: 6px;
	border-radius: 4px;
}

.range-slider__line--side {
	background-color: var(--lightgray);
}

.range-slider__line--main {
	background-color: var(--accent);
}

.range-slider__handle {
	width: 24px;
	height: 24px;
	border: 2px solid var(--white);
}

.filter__controls {
	display: flex;
  flex-wrap: wrap;
}

.filter__footer {
	display: none;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: var(--white);
  padding: 30px 0;
  z-index: 99;
}

.modef-submit {
  margin-right: 10px;
}

.modef-empty {
  width: 100%;
  margin-bottom: 25px;
  font-size: 14px;
}

/*.filter__footer {
	position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: rgba(0,0,0,.5);
    padding: 20px;
}*/
/*div[id^=wait_comp] {
	position: fixed !important;
	width: 100vh !important;
	height: 100vh !important;
	top: 0 !important;
	left: 0 !important;
	background-color: red !important;
	display: block !important;
	padding: 0 !important;
	margin: 0 !important;
	text-align: center;
}*/
