.city {
  position: relative;
  padding-left: 30px;
  margin-right: 30px;
  height: 20px;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: '';
    width: 16px;
    height: 16px;
    background-image: url("../assets/img/icons/geo.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  span {
    font-size: 16px;
    color: var(--white);
  }

  @include m-b-down(687px) {
    display: none;
  }
}
