/*titles*/

.title-md {
  display: inline-block;
  font-size: 36px;

  @include m-b-desktop-down {
    font-size: 32px;
  }

  @include m-b-laptop-down {
    font-size: 28px;
  }

  @include m-b-tablet-lg-down {
    font-size: 24px;
  }

  @include m-b-tablet-down {
    font-size: 20px;
  }
}

.title-sm {
  display: inline-block;
  font-size: 28px;

  @include m-b-desktop-down {
    font-size: 26px;
  }

  @include m-b-laptop-down {
    font-size: 24px;
  }

  @include m-b-tablet-lg-down {
    font-size: 22px;
  }

  @include m-b-tablet-down {
    font-size: 20px;
  }
}

/*section*/

.section {
  margin-bottom: 60px;

  @include m-b-laptop-down {
    margin-bottom: 50px;
  }

  @include m-b-tablet-lg-down {
    margin-bottom: 40px;
  }

  @include m-b-mobile-lg-down {
    margin-bottom: 30px;
  }
}

.card-width {
  width: 100%;
  max-width: 240px;
}

.section-title {
  font-weight: 500;
  font-size: 40px;
  margin-bottom: 30px;
  line-height: 1.2;

  color: var(--dark);

  @include m-b-tablet-down {
    font-size: 32px;
  }

  @include m-b-tablet-down {
    font-size: 26px;
  }
}

.section-padding {
  padding-top: 60px;

  @include m-b-desktop-down {
    padding-top: 60px;
  }

  @include m-b-laptop-down {
    padding-top: 20px;
  }
}

.section-inner-offset {
  padding-top: 60px;
  padding-bottom: 60px;

  @include m-b-desktop-down {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include m-b-laptop-down {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.section-inner-offset-md {
  padding-top: 30px;
  padding-bottom: 30px;

  @include m-b-laptop-down {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.section-inner-offset-xs {
  padding-top: 30px;
  padding-bottom: 30px;

  @include m-b-desktop-down {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  @include m-b-laptop-down {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}

/* flex*/

.flex-centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

/*form elements*/

.fieldset-default {
    margin: 0;
    padding: 0;
    border: 0;
}

/*text*/

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*img*/
.img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/*controls*/

.button-default {
    border: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}

/*scroll*/

.main-scroll {
    &::-webkit-scrollbar {
        background-color: var(--main-scrollbar-color);
        width: var(--main-scrollbar-width);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--main-scrollbar-thumb-color);
        border-radius: 4px;
    }
}

/*resets*/

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.main-btn {
  @extend .btn-reset;
  padding: 7px 10px;
  border: 2px solid var(--dark);
  font-size: 16px;
  color: var(--dark);
}

.accent-btn {
  @extend .button-default;
  @extend .flex-centered-content;
  width: 100%;
  height: 60px;

  font-weight: 500;
  font-size: 18px;

  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: var(--white);

  border-radius: 5px;
  background-color: var(--accent);

  @include m-b-desktop-down {
    height: 50px;
  }

  @include m-b-laptop-down {
    height: 40px;
    font-size: 18px;
  }

  @extend .hover-opacity;
}

.input-default {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #ffffff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

/*containers*/

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);

  @include m-b-desktop-lg-down {
    padding: 0 30px;
  }

  @include m-b-tablet-down {
    padding: 0 20px;
  }

  @include m-b-mobile-lg-down {
    padding: 0 15px;
  }
}

.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-fluid-width);
}

.container-sm {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-sm-width);

  @include m-b-tablet-down {
    padding: 0 20px;
  }

  @include m-b-mobile-lg-down {
    padding: 0 15px;
  }
}


/*elements*/

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.overflow-hidden {
  overflow: hidden;
}

.hover-opacity {
  transition: opacity var(--main-transition);
  @include m-b-tablet-up {
    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}
